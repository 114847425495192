<template>
  <div class="contents" id="contents">
    <div class="inner-sm">
      <!-- Tab Section -->
      <section class="tab-section">
        <!-- Tab -->
        <template v-if="this.userInfo">
          <BaseTab
            v-model="tabValue"
            v-bind:list="[$t('content.event.Event.txt30'), $t('content.event.Event.txt31')]"
            tabtype="type"
          >
            <template v-slot="Scope">
              <li class :class="Scope.paramClass">{{Scope.title}}</li>
            </template>
          </BaseTab>
        </template>
        <template v-else>
          <div class="tab-wrap">
            <ul class="tab type3">
              <li class="on">
                {{$t('content.event.Event.txt32')}}
              </li>
              <div class="tab-bar"></div>
            </ul>
          </div>
        </template>
        <!-- // Tab -->
        <!-- Tab Contents -->
        <router-view
          @input="onTabInput"
            :eventNo="Number(eventNo)"
            :eventInfo="eventInfo"
            :eventDate="Number(eventDate)"
        ></router-view>
        <!-- Tab Contents -->
      </section>
    </div>
  </div>
</template>
<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseTab from '@/components/base/BaseTab.vue';

export default {
  name: 'EventApplyAndHistory',
  mixins: [mixinHelperUtils],
  components: {
    BaseTab,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  props: {
    /**
     * EventNo: 이벤트 항목번호
     */
    eventNo: {
      type: [Number, String],
      required: true,
    },
    /**
     * EventInfo: 이벤트 항목의 상세정보
     */
    eventInfo: {
      type: Object,
      required: true,
    },
    /**
     * EventDate: 이벤트 항목의 달
     */
    eventDate: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tabValue: undefined,
      userInfo: undefined,
    };
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    if (!this.userInfo) {
      this.$router.replace({ name: 'EventApplyNonMember' });
    } else {
      const { path } = this.$route;
      const pathMatch = [/\/apply$/, /\/history$/];
      pathMatch.some((v, i) => {
        const bool = !!path.match(v);
        if (bool) {
          this.tabValue = i;
        }
        return bool;
      });
    }
  },
  watch: {
    tabValue(newValue, oldValue) {
      if (oldValue !== undefined) {
        if (newValue === 0) {
          this.$router.replace({
            name: 'EventApply',
          });
        } else {
          this.$router.replace({
            name: 'EventHistory',
          });
        }
      }
    },
  },
  methods: {
    onTabInput(value) {
      this.tabValue = value;
    },
  },
};
</script>
