<template>
  <div class="event_apply">
    <!-- Tab Section -->
    <section class="tab-section">
      <!-- Tab Contents -->
      <router-view
        @input="onTabInput"
          :eventNo="Number(eventNo)"
          :eventInfo="eventInfo"
          :eventDate="Number(eventDate)"
      ></router-view>
      <!-- Tab Contents -->
    </section>
  </div>
</template>
<script>
import ApplyAndHistory from '@/views/event/ApplyAndHistory.vue';

export default {
  name: 'MobileEventApplyAndHistory',
  extends: ApplyAndHistory,
};
</script>
