<template>
  <div class="tab-wrap">
    <ul
      :class="classes"
      @click="Hander"
    >
      <template v-for="(v, i) in list">
        <slot :paramClass='tabValue === i ? "on" : ""' :title='v' >기본 목록</slot>
      </template>
      <div v-if='tabtype === "type"' class="tab-bar"></div>
    </ul>
  </div>
</template>

<script>
/**
 * 기본 BaseTab 컴포넌트
 */
export default {
  name: 'BaseTab',
  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  model: {
    prop: 'tabValue',
    event: 'input',
  },
  props: {
    tabValue: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
    },
    /**
     * 타입
     * @values type, type2, small-tab
     */
    tabtype: {
      type: String,
      default: 'type',
      validator: (value) => [
        'type',
        'type2',
        'small-tab',
      ].indexOf(value) !== -1,
    },
  },

  computed: {
    classes() {
      return [
        { tab: this.tabtype === 'type' || this.tabtype === 'type2' },
        { type: this.tabtype === 'type' },
        { type2: this.tabtype === 'type2' },
        { 'small-tab': this.tabtype === 'small-tab' },
      ];
    },
  },

  methods: {
    Hander(event) {
      const indexKey = Array.from(event.currentTarget.children).indexOf(event.target);
      for (let i = 0; i < event.currentTarget.children.length; i += 1) {
        if (indexKey === i) {
          event.currentTarget.children[i].classList.add('on');
        } else {
          event.currentTarget.children[i].classList.remove('on');
        }
      }
      /**
       * 선택 이벤트
       * @event input
       * @property {number} 선택된 Tab index
       */
      this.$emit('input', indexKey);
    },
  },
};
</script>

<style scoped>
.tab-wrap .tab.type2 {width:70%; margin:0 auto;}
.tab-wrap .tab.type2 > li {padding-bottom:0; font-size: 30px;}
.tab-wrap .tab.type2 > li:after {content:none}
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```vue
<template>
  <BaseTab
    v-model="tabValue"
    v-bind:list="['대한', '민국']"
    tabtype="type2"
    >
    <template v-slot="Scope">
      <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
    </template>
  </BaseTab>
</template>

<script>
export default {
  data() {
    return {
      tabValue: 0,
    }
  },
  methods: {
  }
};
</script>
```
</docs>
